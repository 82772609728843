.package-main-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 16px;
	overflow-y: scroll;
	height: 100vh;

	.cater-package-form {
		padding: 16px;

		.package-form-input-container {
			display: flex;
			gap: 16px;
			align-items: center;
			flex-wrap: wrap;

			.package-action-btn {
				margin-bottom: 25px;
				white-space: nowrap;
			}
		}
	}

	.cater-package-list {
		padding: 16px;

		.action-buttons-container {
			display: flex;
			gap: 16px;
		}
	}
}
