@import "../../assets/scss/colors.scss";

.side-navigation-wrapper {
	width: 220px;
	top: 70px;
	bottom: 0;
	position: fixed;
	transition: 3s;

	.side-menu-container {
		display: flex;
		flex-direction: column;
		padding: 8px;

		& > a {
			text-decoration: none;
			cursor: pointer;
		}

		.menu-container {
			color: white;
			// cursor: pointer;
			display: flex;
			flex-direction: row;
			margin-top: 5px;
			padding: 8px;

			&:hover {
				background-color: var(--primary-selected);
				border-radius: 10px;
				text-decoration: underline;
			}

			.menu-icon {
				color: var(--text-color-white);
				height: 25px;
				width: 25px;
			}

			.menu-label {
				margin-left: 20px;
				font-size: 16px;
				line-height: 25px;
				color: var(--text-color-white);
			}
		}
	}
}

// @media only screen and (max-width: 1180px) {
//     .main-wrapper {

//         .side-nav-wrapper {

//             // background-color: hotpink;
//             display: none;
//             // visibility: hidden;
//         }

//         .main-container {
//             // background-color: gold;
//             width: 100%;

//             .top-nav-wrapper {

//                 .toggle-icon {
//                     visibility: visible;
//                 }
//             }

//             // .content-wrapper {

//             //     background-color: green;
//             // }
//         }
//     }

// }
